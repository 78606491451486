.auto-screen {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-color: #f0f0f0; /* Adjust background color as needed */
    color: #333;
    font-family: Arial, sans-serif;
    text-align: center;
    overflow: hidden;
  }
  
  .intro-text {
    font-size: 2rem;
    margin: 1rem 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .work-case-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
    position: absolute;
    overflow-y: scroll;
    top: 10%;
  }
  
  .company-details {
    margin-bottom: 1rem;
  }
  
  .company-name {
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  .work-name {
    font-size: 1.2rem;
    margin-top: 0.5rem;
    opacity: 0.8;
  }
  
  .grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 1rem;
    width: 80%;
    margin-bottom: 1rem;
  }
  
  .grid-item {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .grid-item img {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 8px;
  }
  
  .next-button {
    padding: 0.5rem 1rem;
    font-size: 2rem;
    color: #333;
    background: none;
    background-color: none;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 12px;

  }
  
  .next-button:hover {
    background-color: none;
  }
  .header-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
  }