
.graphic-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    height: 100vh;
  }
  
  .back {
    cursor: pointer;
    margin-bottom: 20px;
  }
  
  .logo {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .images {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 10px;
    width: 100%;
    max-width: 1200px;
    margin-top: 20px;
  }
  
  .image-card {
    cursor: pointer;
    overflow: hidden;
    position: relative;
  }
  
  .image-card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s;
  }
  
  .image-card:hover img {
    transform: scale(1.1);
  }
  
  .image-card.large {
    grid-column: span 2;
    grid-row: span 2;
  }
  
  .image-card.small {
    grid-column: span 1;
    grid-row: span 1;
  }
  
  .custom-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
  }
  
  .modal-image {
    max-width: 80%;
    max-height: 80%;
  }
  
  .close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    background: none;
    border: none;
    font-size: 24px;
    color: white;
    cursor: pointer;
  }
  