.graphic-designing {
    position: relative;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;
    color: #ffffff;
    font-family: 'Arial', sans-serif;
    background: #0c0c0c;
    padding-top: 100px;
  }
  
  .logo {
    position: absolute;
    z-index: 99;
    top: 20px;
    font-size: 2rem;
    font-weight: 700;
    color: #ffffff;
  }
  .back{
    position: absolute;
    left: 20px;
    top: 30px;
    text-align: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(78, 78, 82);
    transition: color 0.5s ease;
  }
  .back:hover{
    color: white;
  }
  
  .graphic-cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    gap: 20px;
    width: 90%;
    max-width: 1200px;
    margin: 20px auto;
  }
  
  .graphic-card {
    background: white;
    padding: 10px;
    border-radius: 8px;
    cursor: pointer;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .graphic-card img {
    width: 300px;
    height: 300px;
    border-radius: 8px;
  }
  
  .graphic-card h4 {
    margin-top: 10px;
    font-size: 18px;
    color: black;
  }
  