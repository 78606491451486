.skills-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #0c0c0c;
    color: #ffffff;
    width: 100%;
    height: 100vh;
    padding-top: 18px;
  }
  
  .skills-title {
    font-size: 28px;
    text-transform: uppercase;
    letter-spacing: 4px;
    margin-bottom: 20px;
  }
  
  .skills-card {
    position: relative;
    display: flex;
    align-items: center;
    padding: 15px 15px;
    margin: 10px 0;
    width: 90%;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    overflow: hidden;
  }
  
  .skills-card:hover {
    background-color: #0a0a0a;
  }
  
  .skills-card h4 {
    margin-left: 20px;
    flex-grow: 1;
    z-index: 3;
    transition: transform 0.3s ease;
  }
  
  .icon {
    position: absolute;
    left: -30px;
    transition: left 0.3s ease;
    opacity: 0;
  }
  
  .skills-card:hover .icon {
    left: 20px;
    opacity: 1;
  }
  
  .skills-card:hover h4 {
    transform: translateX(10px);
  }
  