/* src/components/splashscreen/SplashScreen.css */
.splash-screen {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: #0c0c0c;
    color: #ffffff;
    font-family: 'Arial', sans-serif;
  }
  
  .splash-logo {
    font-size: 2rem; /* Decreased size */
    font-weight: 700;
    letter-spacing: 0em;
    color: #ffffff; /* White color */
  }
  