@keyframes shake {
    0% { transform: translate(-50%, -50%) rotate(0deg); }
    25% { transform: translate(-50%, -50%) rotate(-5deg); }
    50% { transform: translate(-50%, -50%) rotate(5deg); }
    75% { transform: translate(-50%, -50%) rotate(-5deg); }
    100% { transform: translate(-50%, -50%) rotate(0deg); }
  }
  
  .circle {
    position: fixed;
    top: 0;
    left: 0;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 1px solid rgba(112, 112, 112, 0.9);
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.1s ease;
    background-color: transparent;
    z-index: 2;
  }
  .circle.hovered {
    background: rgba(51, 51, 51, 0.5);
  }
  
  
  .dot {
    width: 5px;
    height: 5px;
    background: rgba(112, 112, 112, 0.9);
    border-radius: 50%;
  }
  