.graphic-details {
    position: relative;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;
    color: #ffffff;
    font-family: 'Arial', sans-serif;
    background: #0c0c0c;
    padding-top: 100px;
  }
  .back{
    position: absolute;
    left: 20px;
    top: 30px;
    text-align: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(78, 78, 82);
    transition: color 0.5s ease;
  }
  .back:hover{
    color: white;
  }
  

  
  h1, h2 {
    margin: 0px;
    text-align: center;
  }
  
  .companySocialMedia {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 40px;
    align-items: center;
    gap: 20px;
  }
  .logo {
    position: absolute;
    z-index: 99;
    top: 20px;
    font-size: 2rem;
    font-weight: 700;
    color: #ffffff;
  }
  
  .posts {
    margin: 20px 0;
    text-align: center;
  }
  
  .images {
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(auto-fit, minmax(300px, 0.1fr));
    gap: 20px;
    width: 90%;
    max-width: 1200px;
    margin: 20px auto;
  }
  
  .image-card {
    border-radius: 8px;
    cursor: pointer;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out;
  }
  .image-card {
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .image-card img {
    width: 100%;
    height: auto;
    display: block;
    transition: transform 0.3s ease;
    object-fit: cover; /* Center-crop the image */
  }
  
  .image-card:hover img {
    transform: scale(1.1);
  }
  
  
  .image-card:hover {
    transform: scale(1.05);
  }
  
  .react-modal {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-image {
    max-width: 90%;
    max-height: 90vh;
    border-radius: 8px;
  }
  
  .close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    background: none;
    border: none;
    font-size: 1.5rem;
    color: white;
    cursor: pointer;
  }
  