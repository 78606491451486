.developer {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  color: #ffffff;
  font-family: 'Arial', sans-serif;
  background: #0c0c0c;
  padding-top: 100px;
}

.logo {
  position: absolute;
  z-index: 99;
  top: 20px;
  font-size: 2rem;
  font-weight: 700;
  color: #ffffff;
}
.back{
  position: absolute;
  left: 20px;
  top: 30px;
  text-align: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(78, 78, 82);
  transition: color 0.5s ease;
}
.back:hover{
  color: white;
}
.developer-content {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
}

.developer-cards {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 60%;
}

.developer-card {
  position: relative;
  width: calc(50% - 20px);
  margin: 4px;
  padding: 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #080808;
}

.developer-card:hover {
  background-color: #0a0a0a;
  color: #fff;
}

.card-content {
  text-align: center;
}

.card-content h4 {
  margin: 10px 0;
}

.card-content p {
  margin: 5px 0;
}

.card-date {
  font-size: 12px;
  color: #888;
}

.developer-preview {
  width: 40%;
  height: 100vh;
  position: fixed;
  top: 0px;
  right: 0px;
  justify-content: center;
  align-items: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  padding: 20px;
}

.developer-preview img {
  max-width: 100%;
  border-radius: 10px;
}

@media (max-width: 768px) {
  .developer-content {
    flex-direction: column;
  }

  .developer-cards {
    width: 100%;
  }

  .developer-card {
    width: 100%;
  }

  .developer-preview {
    width: 100%;
    margin-top: 20px;
  }
}
