
.social-media-popup {
    position: fixed;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    transition: background 0.3s, backdrop-filter 0.3s;
    padding: 12px;
    border-radius: 12px;
    z-index: 999;
  }
  
  .social-media-popup.scrolled {
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(10px);
  }
  
  .popup-content {
    display: flex;
    gap: 20px;
  }
  
  .popup-content a {
    color: white;
    font-size: 24px;
  }
  