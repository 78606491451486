.home {
    position: relative;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    color: #ffffff;
    font-family: 'Arial', sans-serif;
    background: #0c0c0c;
  }
  .bg{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    background-image: url(back3.png);
    background-position: 100%;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .logo {
    position: absolute;
    z-index: 99;
    top: 20px;
    font-size: 2rem;
    font-weight: 700;
    color: #ffffff;
  }
.line{
  position: fixed;
  left: 50px;
  font-size:60px;
  transform: rotate(90deg);
  display: flex;
  justify-content: center;
}
.line .projectButton{
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: -12px;
  margin-left: 12px;
  letter-spacing: 4px;
}
  
  .content {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    margin-bottom: 2rem;
  }
  .content h1{
    text-align: start;
  }
  .content .highlighted{
    color: rgb(179, 124, 4);
  }
  .content {
  position: relative;
  background: linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.2)),
              url('back2.png') start/cover no-repeat;
              background-attachment: fixed;

}
.social-media{
  position: absolute;
  top: 100px;
  left: 0px;
  z-index: 99;
}

  @media only screen and (max-width: 1050px) {
  
  .line{
    left: -50px;
    bottom: 100px;
    font-size:50px;
  }
}
  @media only screen and (max-width: 600px) {
    .skills-card {
      width: 80%;
    }
    
    
  .content {
    text-align: start;
    justify-content: start;
    margin-left: 30px;
  }
  .content h1{
    font-size: 26px;
    width: 280px;
  }

    .bg{
      background-position: 90%;
    }
  }
  
  