.nav-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60px;
  position: relative;
  background-color: transparent; /* Remove background color */
}
.block{
  display: block;
}
.none{
  display: none;
}

.logo {
  font-size: 24px;
  color: white;
}

.play-button {
  position: absolute;
  right: 20px;
  width: 40px;
  bottom: 12px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #555;
  color: white;
  cursor: pointer;
  font-size: 20px;
}

.song-info {
  position: fixed;
  right: 52px;
  bottom: 18px;
  padding: 5px 10px;
  background-color: #444;
  color: white;
  border-radius: 5px;
  font-size: 16px;
  display: flex;
  align-items: center;
}

.hamburger-menu {
  position: absolute;
  left: 20px; /* Position hamburger button to the left */
  width: 40px;
  top: 12px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  color: white;
  cursor: pointer;
  font-size: 24px; /* Adjust icon size */
  z-index: 10;
}

.close-menu {
  position: absolute;
  right: 20px;
  top: 12px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  color: white;
  cursor: pointer;
  font-size: 24px; /* Adjust icon size */
  z-index: 10;
}

.full-screen-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.5s;
  z-index: 5;
}

.full-screen-menu.show {
  opacity: 1;
  pointer-events: all;
}

.menu-item {
  font-size: 26px;
  margin: 6px;
  padding: 0;
  cursor: pointer;
  color: white;
  opacity: 0;
  transform: translateX(-50px);
  animation: slideIn 0.5s forwards;
  text-transform: uppercase;
  position: relative;
}

.menu-item::after {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: white;
  transform: scaleX(0); /* Initial scale for animation */
  transition: transform 0.3s;
}

.menu-item:hover::after {
  transform: scaleX(1); /* Scale to show the bottom border */
}

.menu-item:nth-child(1) {
  animation-delay: 0.1s;
}

.menu-item:nth-child(2) {
  animation-delay: 0.2s;
}

.menu-item:nth-child(3) {
  animation-delay: 0.3s;
}

@keyframes slideIn {
  0% {
    transform: translateX(-50px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
