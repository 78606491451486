.feedback-section {
  max-width: 1200px;
  margin: auto;
  padding: 20px;
}

.feedback-section h2 {
  text-align: center;
  width: 100%;
  font-size: 30px;
  text-transform: uppercase;
  color: darkgrey;
  letter-spacing: 4px;
  margin-bottom: 40px;
}

.feedback-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #141414;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  padding: 20px;
  transition: transform 0.3s, box-shadow 0.3s;
}

.feedback-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.feedback-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.feedback-profile img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 20px;
}

.feedback-details {
  flex-grow: 1;
}

.feedback-details h3 {
  font-size: 20px;
  margin-bottom: 10px;
}

.feedback-details p {
  margin: 5px 0;
  font-size: 16px;
  line-height: 1.5;
}

.feedback-details a {
  display: inline-flex;
  align-items: center;
  color: #007BFF;
  text-decoration: none;
  transition: color 0.3s;
}

.feedback-details a:hover {
  color: #0056b3;
}

.feedback-details a svg {
  margin-left: 5px;
}
