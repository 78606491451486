.project-default-page {
    padding: 20px;
    font-family: Arial, sans-serif;
    text-align: center;
  }
  
  .back {
    cursor: pointer;
    font-size: 16px;
    margin-bottom: 20px;
  }
  
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .content h1 {
    font-size: 24px;
    color: #333;
  }
  
  .content p {
    font-size: 18px;
    color: #666;
  }
  