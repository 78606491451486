.projects-section {
    display: flex;
    height: 100vh;
    background-color: black;
    background: black;
    overflow-x: hidden;
  }
  .projects-section::-webkit-scrollbar {
    display: none;
}
  
  .three-container {
    flex: 1;
    background: black;
  }
  
  .projects-list {
    flex: 1;
    overflow-y: scroll;
    padding: 20px;
    background: black;
  }
  
  .projects-list::-webkit-scrollbar {
    display: none;
  }
  
  .projects-title {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #ccc;
  }
  
  .project-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
    cursor: pointer;
    transition: background 0.3s;
  }
  
  .project-card:hover {
    background: #0c0c0c;
  }
  
  .project-info h4 {
    margin: 0;
    font-size: 1.2rem;
    color: #ccc;
  }
  
  .project-info p {
    margin: 0;
    font-size: 0.7rem;
    color: #666;
  }
  
  .project-info span {
    font-size: 0.8rem;
    color: #999;
  }
  .back{
    position: absolute;
    left: 20px;
    top: 30px;
    text-align: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(78, 78, 82);
    transition: color 0.5s ease;
    z-index: 1000;
  }
  .back:hover{
    color: white;
  }
  @media only screen and (max-width: 720px) {
    .projects-section {
      display: flex;
      height: 100vh;
    }
     
  .three-container {
    position: fixed;
  }
    .projects-list  {
      position: absolute;
      left:0px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: rgba(0,0,0,0.1);
      background-color: rgba(0,0,0,0.1);

    }
    .projects-list {
      padding: 0px;
      padding-top: 20px;
    }
    .project-card{
      background: rgba(0,0,0,.1);
      background-color: rgba(0,0,0,.1);
      width: 90%;
    }
  }
  