
.cases-section {
    max-width: 1200px;
    margin: auto;
    padding: 20px;
    background-color: #0c0c0c;
  }
  
  .cases-section h2 {
    text-align: center;
    width: 100%;
    font-size: 30px;
    text-transform: uppercase;
    color: darkgrey;
    letter-spacing: 4px;
    margin-bottom: 40px;
  }
  
  .case-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #202020;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    padding: 20px;
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .case-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .case-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  
  .case-details {
    flex-grow: 1;
    text-align: center;
  }
  
  .case-details h3 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .case-details p {
    margin: 5px 0;
    font-size: 16px;
    line-height: 1.5;
  }
  
  .case-details a {
    display: inline-flex;
    align-items: center;
    color: #007BFF;
    text-decoration: none;
    transition: color 0.3s;
  }
  
  .case-details a:hover {
    color: #0056b3;
  }
  
  .case-details a svg {
    margin-left: 5px;
  }
  