.work-case-page {
    padding: 20px;
    font-family: Arial, sans-serif;
    background-color: #000 ;
    color: #ccc;
    height: 100vh;
  }
  
  .back {
    cursor: pointer;
    margin-bottom: 20px;
    font-size: 16px;
  }
  
  .header {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .header h1 {
    font-size: 20px;
    margin: 0;
  }
  
  .header p {
    font-size: 18px;
    color: #666;
  }
  
  .project-details {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .section {
    margin-bottom: 40px;
  }
  
  .section h2 {
    font-size: 20px;
    margin-bottom: 20px;
    border-bottom: 2px solid #1a1a1a;
    padding-bottom: 10px;
  }
  
  .images-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .image-card {
    position: relative;
  }
  
  .image-card.large {
    flex: 1 1 60%;
  }
  
  .image-card.small {
    flex: 1 1 30%;
  }
  
  .image-card img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  @media (max-width: 768px) {
    .image-card.large {
      flex: 1 1 100%;
    }
  
    .image-card.small {
      flex: 1 1 100%;
    }
  }
  